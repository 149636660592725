<template>
  <div class="full-page">
    <div class="header">
      <div class="logo">
        <img src="@/assets/imgs/website/logo.png" alt="">
      </div>
      <div class="nav-bar">
        <div class="nav-item"
        v-for="(item, index) in navList"
        :key="index" @click="navIndex = index"
        >
          <a class="nav-item-link" :class="{ active: navIndex === index }" :href="`#nav-${item.id}`">{{ item.title }}</a>
          <div class="line-active" v-if="navIndex === index"></div>
        </div>
      </div>
    </div>
    <div class="banner">
      <img class="banner-bg" src="@/assets/imgs/website/banner.png" alt="">
      <div class="banner-content">
        <img class="banner-phone" src="@/assets/imgs/website/banner-phone.png" alt="">
        <div class="banner-right">
          <div class="banner-text1">¡Desembolso rápido con montos de hasta</div>
          <div class="banner-text2">$3.000.000!</div>
          <div class="color-box">
            <div class="color-box-text">De <span>120 a 180</span> días</div>
            <div class="color-box-text">Recibe tu dinero <span>en 1 a 5</span> minutos</div>
          </div>
          <div class="banner-text3">Revisión sencilla, alta tasa de aprobación</div>
          <div class="banner-btns">
            <img class="banner-btn" src="@/assets/imgs/website/banner-btn.png" @click="toGoolePlay">
            <img class="banner-btn" src="@/assets/imgs/website/banner-btn2.png" @click="toAppGallery">
          </div>
        </div>
      </div>
    </div>
    <div id="nav-1" class="page-box-1">
      <div class="box-1-left">
        <img class="box1-item-cover" src="@/assets/imgs/website/box1-1.png">
        <div class="box1-item-title">Institución financiera especializada en créditos y préstamos</div>
        <div class="box1-item-desc">Como una institución financiera experta en créditos y préstamos, nos hemos dedicado a ofrecer continuamente a nuestros clientes servicios de créditos rápidos, accesibles y seguros. A lo largo del tiempo, hemos seguido los principios de honestidad, profesionalismo e innovación, mejorando continuamente la calidad del servicio y la satisfacción del cliente, ganándonos su confianza y el respaldo de una amplia base de usuarios.</div>
      </div>
      <div class="box-1-right">
        <div class="box-1-right-item">
          <img class="box1-item-cover" src="@/assets/imgs/website/box1-2.png">
          <div class="box1-item-content">
            <div class="box1-item-title">Años de experiencia</div>
            <div class="box1-item-desc">Poseemos más de 8 años de trayectoria en el sector y aplicamos estándares crediticios.</div>
          </div>
        </div>
        <div class="box-1-right-item">
          <img class="box1-item-cover" src="@/assets/imgs/website/box1-3.png">
          <div class="box1-item-content">
            <div class="box1-item-title">Múltiples canales</div>
            <div class="box1-item-desc">A través de nuestras diversas vías, proporcionamos servicios financieros a más de 30,000 usuarios.</div>
          </div>
        </div>
      </div>
    </div>
    <div id="nav-2" class="page-box-2">
      <div class="box-2-title">Propósito y perspectiva</div>
      <div class="box-2-content">
        <div class="box2-item" v-for="(item, index) in box2List" :key="index">
          <img class="box2-item-icon" :src="require(`@/assets/imgs/website/${item.coverName}.png`)" />
          <div class="box2-item-right">
            <div class="box2-item-title">{{ item.title }}</div>
            <div class="box2-item-desc">{{  item.content }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="nav-3" class="page-box-3">
      <div class="box-3-title">Presentación de productos crediticios</div>
      <div class="box-3-sub-title">Proveemos servicios de créditos personales para cubrir sus requerimientos financieros. Se trata de un sistema completamente en línea, al que puede acceder de forma rápida y fácil.</div>
      <div class="box-3-content">
        <div class="box-3-content-item" 
        v-for="(item, index) in box3List" 
        :key="index"
        :class="{ active: box3CurrnetIndex === index }"
        >
          <div class="box3-content" :class="{'box3-active': box3CurrnetIndex === index}">
            <div class="box3-text-title">{{ item.title }}</div>
            <div class="box3-text-content">
              <div class="box3-text-content-item" v-for="(item, index) in item.content" :key="index">
                <div class="content-item-sort">{{ `0${index + 1}` }}</div>
                <div class="content-item-text">{{ item }}</div>
              </div>
            </div>
            <img v-if="index !== 0" class="content-item-img" :src="require(`@/assets/imgs/website/${item.coverName}.png`)" alt="">
          </div>
        </div>
      </div>
    </div>
    <div id="nav-4" class="page-box-4">
      <div class="box-4-title">inconveniente habitual</div>
      <div class="box-4-content">
        <div class="box-4-content-item" v-for="(item, index) in box4List" :key="index" @click="item.showMore =!item.showMore">
          <div class="content-header">
            <img class="content-item-question" src="@/assets/imgs/website/question.png">
            <div class="content-item-title">{{ item.title }}</div>
            <div class="content-item-arrow">
              <img v-if="item.showMore" src="@/assets/imgs/website/up.png" alt="">
              <img v-else src="@/assets/imgs/website/down.png" alt="">
            </div>
          </div>
          <div v-if="item.showMore" class="content-item-desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div id="nav-5" class="page-box-5">
      <div class="box-5-content">
        <div class="box-5-title">acerca de nosotros</div>
        <div class="box-5-content-desc">
          <p>Con capital variable, estamos registrados como una entidad financiera de objeto múltiple no regulada (SOFOM ENR). Nuestros productos están dirigidos a las zonas rurales y a las áreas combinadas de población urbana y rural, atendiendo a personas involucradas en diversas actividades productivas. Contamos con una alta satisfacción por parte de nuestros clientes.</p>
          <p>Nuestros productos brindan numerosas ventajas para nuestros clientes.</p>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <img class="gold" src="@/assets/imgs/website/gold.png" alt="">
      <div class="footer-list">
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-1.png" alt="">
          <div class="footer-item-text">
            <p>Nombre de empresa</p>
            <p class="bold">TAC MAC COLOMBIA SAS</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-2.png" alt="">
          <div class="footer-item-text">
            <p>Email</p>
            <p class="bold">{{ customerServiceInfo.email }}</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-3.png" alt="">
          <div class="footer-item-text">
            <p> Dirección de la empresa</p>
            <p class="bold">CL48 NO.18 71,Bogota D.C,COLOMBIA</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-5.png" alt="">
          <div class="footer-item-text">
            <p>Teléfono</p>
            <p class="bold">{{ customerServiceInfo.csMobile }}</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-6.png" alt="">
          <div class="footer-item-text">
            <p>WhatsApp</p>
            <p class="bold"
              v-for="(item, index) in customerServiceInfo.whatsappList"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-7.png" alt="">
          <div class="footer-item-text">
            <p>D-U-N-S<span style="scale: 0.7; vertical-align: super;">®</span> Number</p>
            <p class="bold">886749145</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-4.png" alt="">
          <div class="footer-item-text">
            <a class="active" target="_blank" href="https://sites.google.com/view/rapcash-privacy">acuerdo de privacidad</a>
          </div>
        </div>
      </div>
      <div class="page-bottom-box">
        <img class="logo" src="@/assets/imgs/website/logo.png">
        <div>
          <p>Bajo la supervisión de la Superintendencia Financiera de Colombia.</p>
          <p>Todos los derechos reservados.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getAnalytics, logEvent } from "firebase/analytics"
// const analytics = getAnalytics()

import CryptoJS from 'crypto-js'

export default {
  data() {
    return {
      navIndex: 0,
      navList: [
        { id: 1, title: 'portada' },
        { id: 2, title: 'Propósito y perspectiva' },
        { id: 3, title: 'asistencia del producto' },
        { id: 4, title: 'inconveniente habitual' },
        { id: 5, title: 'acerca de nosotros' },
      ],
      currnetTab: 0,
      tabs: [
        { id: 1, title: 'Misión' },
        { id: 2, title: 'Visión' },
        { id: 3, title: 'Nuestras fortalezas' }
      ],
      box1CurrentIndex: 0,
      box2List: [
        {
          coverName: 'box2-1',
          title: 'Eficiente',
          content: 'Nuestra misión es crear mayor valor para nuestros clientes, ayudándoles a alcanzar la libertad financiera y hacer realidad sus sueños. Permaneceremos firmes en los principios de integridad, profesionalismo e innovación, buscando constantemente mejorar la calidad del servicio y la satisfacción del cliente, con el objetivo de ofrecer préstamos de la máxima excelencia.'
        },
        {
          coverName: 'box2-2',
          title: 'Eficiencia y velocidad',
          content: 'Aplicamos tecnologías avanzadas y una gestión de procesos eficaz para responder rápidamente a las necesidades de nuestros clientes, ofreciendo servicios de préstamos eficaces y ágiles.'
        },
        {
          coverName: 'box2-3',
          title: 'Adaptabilidad personalizada',
          content: 'Ajustamos nuestras soluciones de financiamiento según la situación única de cada cliente, proporcionando planes personalizados que satisfacen diversas necesidades de préstamo.'
        },
        {
          coverName: 'box2-4',
          title: 'Eficiente y conveniente',
          content: 'Implementamos tecnologías avanzadas y una gestión eficaz de procesos para responder con rapidez a las necesidades de nuestros clientes, ofreciendo servicios de préstamos efectivos y ágiles.'
        },
        {
          coverName: 'box2-5',
          title: 'Personalización adaptable',
          content: 'Ajustamos nuestras soluciones de financiamiento según la situación particular de cada cliente, proporcionando planes personalizados que satisfacen diversas necesidades de préstamo.'
        },
        {
          coverName: 'box2-6',
          title: 'Ventajas de tasas reducidas',
          content: 'Basándonos en la evaluación crediticia y el historial de pagos de nuestros clientes, ofrecemos tasas de interés competitivas para ayudarles a reducir los costos de amortización.'
        },
      ],
      box3CurrnetIndex: 0,
      box3List: [
        {
          coverName: 'box3-2',
          title: '¿Por qué elegir nuestros ?',
          content: [
            'Proceso completamente en línea: Sin papeleo ni complicaciones legales.',
            'Desembolso rápido: Obtener un crédito es sencillo y ágil. Una vez aprobado, recibirá el dinero de inmediato.',
            'Pagos puntuales para aumentar el límite de crédito y obtener plazos más extensos.',
            'Su historial crediticio se compartirá con agencias externas de calificación crediticia; esto puede mejorar o reducir su puntuación crediticia.',
            'Servicio de excelencia: Ofrecemos atención profesional y oportuna las 24 horas del día, los 7 días de la semana.',
          ]
        },
        {
          coverName: 'box3-2',
          title: 'Pasos para solicitar un crédito en línea',
          content: [
            'Descargue la app de préstamos RapCash.',
            'Rellene el formulario de solicitud.'
          ]
        },
        {
          coverName: 'box3-3',
          title: 'Requisitos para solicitar un crédito',
          content: [
            'Tener al menos 18 años.',
            'Ser ciudadano colombiano y residir en Colombia.'
          ]
        },
      ],
      box4List: [
        {
          title: 'Ya no trabajo y me están solicitando información laboral, ¿qué debo hacer?',
          desc: 'Nuestra misión es crear mayor valor para nuestros clientes, ayudándoles a alcanzar la libertad financiera y hacer realidad sus sueños. Permaneceremos firmes en los principios de integridad, profesionalismo e innovación, buscando constantemente mejorar la calidad del servicio y la satisfacción del cliente, con el objetivo de ofrecer préstamos de la máxima excelencia.',
          showMore: true
        },
        {
          title: '¿Cómo evaluamos su solicitud de crédito?',
          desc: 'El monto del crédito se determinará según sus ingresos y capacidad de pago.',
          showMore: false
        },
        {
          title: '¿Cuánto tiempo tardaré en recibir el crédito?',
          desc: 'Después de la aprobación, el dinero se transferirá a su cuenta en un plazo de 3 minutos y se depositará en su cuenta bancaria. Sin embargo, debido a posibles problemas de red, puede haber retrasos. Consulte a su banco para conocer la hora exacta de llegada.',
          showMore: false
        },
        {
          title: '¿Cuánto dinero recibiré realmente del crédito solicitado?',
          desc: 'El monto del crédito que se depositará en su cuenta bancaria ya ha sido deducido de los cargos por servicio e intereses del crédito.',
          showMore: false
        },
        {
          title: '¿Por qué no puedo solicitar una cantidad mayor?',
          desc: 'Debe comenzar solicitando el producto más básico. Conforme a su historial crediticio y de pagos, su crédito se irá construyendo gradualmente y el monto aumentará progresivamente.',
          showMore: false
        },
        {
          title: '¿Puedo cancelar el crédito?',
          desc: 'Una vez presentada la solicitud de crédito, no se puede cancelar.',
          showMore: false
        },
        {
          title: '¿Cómo recibiré mi dinero?',
          desc: 'Los fondos se transferirán directamente a su cuenta bancaria el siguiente día hábil.',
          showMore: false
        },
        {
          title: '¿Qué hago si olvido realizar un pago?',
          desc: 'Si tiene problemas de liquidez y no puede pagar el crédito a tiempo, póngase en contacto con su prestamista para discutir la posibilidad de renovar el crédito.',
          showMore: false
        },
      ],
      customerServiceInfo: {}
    }
  },

  created() {
    this.getCustomerServiceInfo()
  },

  methods: {
    getCustomerServiceInfo() {
      const timestamp = this.getCurrentTimeFormattedUTC()
      const sign = CryptoJS.MD5(`timestamp=${timestamp}&key=credyclub7240d281dka3fjdka13dm31d11823905`).toString()
      this.$request({
        url: '/home/customerServiceInfo',
        data: {
          data: {
            timestamp
          },
          sign
        }
      }).then((res) => {
        console.log('res :>> ', res)
        this.customerServiceInfo = res.data || {}
      })
    },

    getCurrentTimeFormattedUTC() {
      const now = new Date()
      const year = now.getUTCFullYear().toString()
      const month = ('0' + (now.getUTCMonth() + 1)).slice(-2)
      const day = ('0' + now.getUTCDate()).slice(-2)
      const hours = ('0' + now.getUTCHours()).slice(-2)
      const minutes = ('0' + now.getUTCMinutes()).slice(-2)
      const seconds = ('0' + now.getUTCSeconds()).slice(-2)

      return `${year}${month}${day}${hours}${minutes}${seconds}`
    },

    changeTab(index) {
      this.currnetTab = index
    },

    toGoolePlay() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.rapcash'
    },

    toAppGallery() {
      window.location.href = 'https://www.rapcash1.com/download/rapcash.apk'
    }
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  width: 100%;
  // background-color: #F5F9FF;
  .header {
    padding: 30px 5%;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    .logo {
      width: 193px;
      img {
        width: 100%;
      }
    }
    .nav-bar {
      display: flex;
      align-items: center;
      .nav-item {
        position: relative;
        cursor: pointer;
        margin-left: 48px;
        .nav-item-link {
          font-size: 21px;
          color: #333;
          &.active {
            color: #D43939;
            font-weight: 900;
          }
        }
        .line-active {
          width: 60px;
          height: 4px;
          background: #D43939;
          position: absolute;
          bottom: -24px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .banner {
    width: 100%;
    height: 800px;
    position: relative;
    .banner-bg {
      width: 100%;
      height: 800px;
      object-fit: cover;
      display: block;
    }
    .banner-content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .banner-right {
        flex: 1;
        margin-left: 70px;
       .banner-text1 {
          width: 600px;
          font-size: 46px;
          color: #101010;
          font-weight: 900;
        }
        .banner-text2 {
          font-size: 80px;
          color: #D43939;
          font-weight: 900;
          margin: 20px 0;
        }
        .color-box {
          margin: 20px 0;
          padding: 20px 24px;
          width: 680px;
          background: linear-gradient( 90deg, #FFCCCC 0%, rgba(255,204,204,0) 100%);
          border-left: 6px solid #D43939;
          .color-box-text {
            font-size: 36px;
            font-weight: bold;
            color: #000000;
            span {
              font-size: 36px;
              color: #F5111D;
            }
          }
        }
        .banner-text3 {
          font-size: 28px;
          color: #000;
        }
        .banner-btns {
          display: flex;
          margin-top: 53px;
          .banner-btn {
            margin-right: 27px;
            width: 253px;
            height: 75px;
            cursor: pointer;
          }
        }
      }
     .banner-phone {
        width: 938px;
        margin-left: 126px;
        margin-bottom: -100px;
      }
    }
  }

  .page-box-1 {
    padding: 120px 0;
    height: 880px;
    display: flex;
    justify-content: center;
    background: url("../../../assets/imgs/website/box-1-bg.png") no-repeat;
    background-size: 100% 100%;
    .box1-item-cover {
      width: 230px;
    }
    .box1-item-title {
      width: 530px;
      margin-top: 40px;
      font-size: 24px;
      font-weight: 900;
      color: #000;
    }
    .box1-item-desc {
      margin-top: 16px;
      font-size: 20px;
      color: #666666;
    }
    .box-1-left {
      width: 640px;
      background-color: #fff;
      border-radius: 16px;
      box-shadow: 8px 8px 24px 0px rgba(102,102,102,0.1);
      padding: 40px;
    }
    .box-1-right {
      margin-left: 40px;
      .box-1-right-item {
        padding: 0 50px;
        width: 840px;
        height: 300px;
        background-color: #fff;
        box-shadow: 8px 8px 24px 0px rgba(102,102,102,0.1);
        border-radius: 16px;
        margin-bottom: 40px;
        display: flex;
        align-items: center;
        .box1-item-cover {
          width: 200px;
        }
        .box1-item-content {
          margin-left: 65px;
          .box1-item-title {
            margin: 0;
          }
        }
      }
    }
  }

  .page-box-2 {
    margin-top: 80px;
    padding: 0 200px;
    .box-2-title {
      font-size: 53px;
      color: #000;
      text-align: center;
      font-weight: 900;
    }
    .box-2-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .box2-item {
        width: 47%;
        margin-top: 80px;
        display: flex;
        .box2-item-icon {
          width: 86px;
          height: 100px;
        }
        .box2-item-right {
          margin-left: 24px;
          .box2-item-title {
            font-size: 24px;
            font-weight: 900;
          }
          .box2-item-desc {
            margin-top: 16px;
            font-size: 20px;
            color: #666666;
          }
        }
      }
    }
  }

  .page-box-3 {
    padding: 80px 160px;
    margin-top: 133px;
    background: url('../../../assets/imgs/website/box-3-bg.png') no-repeat;
    background-size: 100%;
    .box-3-title {
      width: 700px;
      font-weight: 900;
      font-size: 53px;
    }
    .box-3-sub-title {
      width: 800px;
      margin-top: 32px;
      font-size: 24px;
      color: #666;
    }
    .box-3-content {
      margin-top: 80px;
      border-radius: 21px;
      display: flex;
      .box-3-content-item {
        flex: 1;
        padding: 40px;
        background: rgba(255,255,255,0.6);
        margin-right: 27px;
        border-radius: 16px;
        overflow: hidden;
        position: relative;
        &.active {
          // height: 780px;
          flex: 2;
          cursor: default;
          background: linear-gradient( 90deg, #FFCCCC 0%, rgba(255,204,204,0) 100%);
          background-size: 100% 100%;
          box-shadow: 16px 16px 32px 0px rgba(56,106,255,0.1);
          border: 1px solid #D43939;
          border-radius: 16px;
          // border-image: linear-gradient(180deg, rgba(56, 106, 255, 1), rgba(255, 255, 255, 0)) 1 1;
        }
        .content-item-img {
          width: 100px;
          height: 100px;
          position: absolute;
          right: 0;
          bottom: 0;
        }
       .content-item-title {
          font-size: 24px;
          color: #000000;
          font-weight: 900;
          margin-bottom: 20px;
        }

        .box3-content {
          box-sizing: border-box;
          &.box3-active {
          .box3-text-title {
            font-size: 32px;
          }
        }
          .box3-text-title {
            font-size: 20px;
            color: #000;
            font-weight: 900;
            line-height: 32px;
          }
          .box3-text-content {
            margin-top: 20px;
            .box3-text-content-item {
              margin-bottom: 15px;
              display: flex;
              .content-item-sort {
                font-size: 24px;
                color: #D43939;
                font-weight: 900;
                margin-right: 12px;
                text-decoration: underline;
              }
              .content-item-text {
                font-size: 20px;
                color: #666666;
              }
            }
          }
        }
      }
    }
  }

  .page-box-4 {
    margin-top: 80px;
    padding: 0 160px;
    .box-4-title {
      font-size: 53px;
      color: #000;
      text-align: center;
      font-weight: 900;
    }
    .box-4-content {
      margin-top: 80px;
      .box-4-content-item {
        background-color: #fff;
        border-radius: 16px;
        margin-bottom: 27px;
        position: relative;
        .content-header {
          padding: 23px 40px;
          background: #F5F5F5;
          border-radius: 16px;
          display: flex;
          .content-item-question {
            width: 29px;
            height: 34px;
            margin-right: 10px;
          }
          .content-item-title {
            flex: 1;
            font-size: 24px;
            color: #000;
            font-weight: 900;
          }
          .content-item-arrow {
            width: 32px;
            height: 32px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        .content-item-desc {
          padding: 0 80px;
          margin: 20px 0 40px;
          font-size: 21px;
          color: #666;
        }
      }
    }
  }
  
  .page-box-5 {
    margin-top: 80px;
    .box-5-content {
      padding: 120px 240px;
      background: url("../../../assets/imgs/website/about_us.png") no-repeat;
      background-size: 100%;
      .box-5-title {
        font-size: 54px;
        font-weight: 900;
        color: #fff;
      }
      .box-5-content-desc {
        margin-top: 40px;
        width: 700px;
        color: #fff;
        line-height: 28px;
        p {
          font-size: 21px;
          margin-bottom: 27px;
        }
      }
      .box-5-content-right {
        margin-left: 60px;
        width: 320px;
        height: 320px;
      }
    }
  }

  .footer-box {
    padding: 80px 206px 0;
    position: relative;
    .gold {
      width: 416px;
      height: 416px;
      position: absolute;
      right: 76px;
      top: 0;
    }
    .logo {
      width: 254px;
    }
    .footer-list {
      display: flex;
      flex-wrap: wrap;
      .footer-item {
        width: 50%;
        display: flex;
        align-items: flex-start;
        margin-bottom: 50px;
        .footer-item-icon {
          width: 36px;
          margin-right: 16px;
        }
        .footer-item-text {
          color: #000;
          p {
            font-size: 20px;
          }
          .bold {
            margin-top: 5px;
            font-weight: 900;
          }
          .active {
            font-size: 20px;
            font-weight: 900;
            color: #386AFF;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
    .page-bottom-box {
      padding: 45px 0;
      border-top: 1px solid #DDDDDD;
      display: flex;
      justify-content: space-between;
      .logo {
        width: 193px;
        height: 50px;
      }
      p {
        text-align: right;
        color: #999999;
        font-size: 20px;
        margin-bottom: 13px;
      }
    }
  }

  .two-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>