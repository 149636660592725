<template>
  <div class="full-page">
    <img class="page-bg" src="@/assets/imgs/mb/mb-bg.png" alt="">
    <div class="main">
      <img class="logo" src="@/assets/imgs/mb/logo.png" alt="">
      <div class="header">
        <div class="text-1">¡Desembolso rápido con montos de hasta</div>
        <div class="text-2">$3.000.000!</div>
        <div class="text-3">
         <p>De <span class="active">120 a 180</span> días</p>
         <p>Recibe tu dinero <span class="active">en 1 a 5</span> minutos</p>
        </div>
      </div>

      <img class="phone" src="@/assets/imgs/mb/phone.png" alt="">
      <div ref="target">
        <div class="btn-box" :class="buttonClass">
          <div class="btn" style="margin-right: 12px;" @click="toGoolePlay">
            <img src="@/assets/imgs/mb/google-play.png" alt="">
          </div>
          <div class="btn" @click="toAppGallery">
            <img src="@/assets/imgs/mb/app-gallery.png" alt="">
          </div>
        </div>
      </div>

      <div class="footer">
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/mb/footer-icon-1.png" alt="" />
          <div class="footer-item-text">
            <p>Nombre de empresa</p>
            <p class="bold">TAC MAC COLOMBIA SAS</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/mb/footer-icon-4.png" alt="" />
          <div class="footer-item-text">
            <p>Dirección de la empresa</p>
            <p class="bold">CL48 NO.18 71,Bogota D.C,COLOMBIA</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/mb/footer-icon-2.png" alt="" />
          <div class="footer-item-text">
            <p>Email</p>
            <p class="bold">{{ customerServiceInfo.email }}</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-5.png" alt="">
          <div class="footer-item-text">
            <p>Teléfono</p>
            <p class="bold">{{ customerServiceInfo.csMobile }}</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-6.png" alt="">
          <div class="footer-item-text">
            <p>WhatsApp</p>
            <p class="bold"
              v-for="(item, index) in customerServiceInfo.whatsappList"
              :key="index"
            >
              {{ item }}
            </p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/website/footer-icon-7.png" alt="">
          <div class="footer-item-text">
            <div>D-U-N-S<div style="vertical-align: super; display: inline-block; margin-top: -7px;">®</div> Number</div>
            <p class="bold">886749145</p>
          </div>
        </div>
        <div class="footer-item">
          <img class="footer-item-icon" src="@/assets/imgs/mb/footer-icon-3.png" alt="" />
          <div class="footer-item-text">
            <a class="active" target="_blank" href="https://sites.google.com/view/rapcash-privacy">acuerdo de privacidad</a>
          </div>
        </div>
        <div class="footer-text">
          Es sujeto a la vigilancia de la Comisión Nacional de Bancos y Valores, y se mantienen reservados todos los derechos
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'

export default {
  data() {
    return {
      buttonClass: 'isFixed', // 控制按钮的定位
      customerServiceInfo: {}
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.getCustomerServiceInfo()
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const targetRect = this.$refs.target.getBoundingClientRect()
      const windowBottom = window.innerHeight
      if (targetRect.top + 48 <= windowBottom) {
        this.buttonClass = ''  // 到达目标，取消fixed定位
      } else {
        this.buttonClass = 'isFixed'  // 未到达目标，保持fixed定位
      }
    },

    getCustomerServiceInfo() {
      const timestamp = this.getCurrentTimeFormattedUTC()
      const sign = CryptoJS.MD5(`timestamp=${timestamp}&key=credyclub7240d281dka3fjdka13dm31d11823905`).toString()
      this.$request({
        url: '/home/customerServiceInfo',
        data: {
          data: {
            timestamp
          },
          sign
        }
      }).then((res) => {
        console.log('res :>> ', res)
        this.customerServiceInfo = res.data || {}
      })
    },

    getCurrentTimeFormattedUTC() {
      const now = new Date()
      const year = now.getUTCFullYear().toString()
      const month = ('0' + (now.getUTCMonth() + 1)).slice(-2)
      const day = ('0' + now.getUTCDate()).slice(-2)
      const hours = ('0' + now.getUTCHours()).slice(-2)
      const minutes = ('0' + now.getUTCMinutes()).slice(-2)
      const seconds = ('0' + now.getUTCSeconds()).slice(-2)

      return `${year}${month}${day}${hours}${minutes}${seconds}`
    },

    toGoolePlay() {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.rapcash'
    },

    toAppGallery() {
      window.location.href = 'https://www.rapcash1.com/download/rapcash.apk'
    }
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  width: 100%;
  height: 100%;
  position: relative;
 .page-bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .main {
    position: relative;
    .logo {
      margin: 40px auto 0;
      display: block;
      width: 173px;
      height: 45px;
    }
    .header {
      padding: 10px 20px;
      text-align: center;
      .text-1 {
        font-size: 27px;
        font-weight: 900;
        color: #fff;
        line-height: 28px;
      }
      .text-2 {
        margin: 15px 0;
        font-size: 40px;
        font-weight: 900; 
        color: #FAFF00;
      }
      .text-3 {
        padding: 0 30px;
        p {
          font-size: 18px;
          color: #fff;
          .active {
            font-size: 18px;
            color: #FAFF00;
          }
        }
      }
    }
    .phone {
      margin: 10px 0 20px;
      width: 100%;
    }
    .btn-box {
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      &.isFixed {
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 20px;
      }
      .btn {
        flex: 1;
        img {
          width: 100%;
        }
      }
    }

    .footer {
      margin-top: 20px;
      padding: 0 20px;
      padding-bottom: calc(constant(safe-area-inset-bottom));
      padding-bottom: calc(env(safe-area-inset-bottom));
      .footer-item {
        margin-bottom: 20px;
        display: flex;
        align-items: flex-start;
        .footer-item-icon {
          width: 20px;
          margin-right: 16px;
        }
        .footer-item-text {
          color: #000;
          p {
            font-size: 14px;
          }
          .bold {
            margin-top: 5px;
            font-weight: 900;
          }
          .active {
            font-size: 14px;
            font-weight: 900;
            color: #386AFF;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
      .footer-text {
        padding: 20px 0;
        font-size: 12px;
        color: #999999;
        line-height: 20px;
        border-top: 1px solid #D9D9D9;
      }
    }
  }
}
</style>