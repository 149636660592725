<template>
  <div>
    <Mobile v-if="isMobile" />
    <Pc v-else />
  </div>
</template>

<script>
import Mobile from './components/mobile.vue'
import Pc from './components/pc.vue'

export default {
  components: {
    Mobile,
    Pc
  },

  data() {
    return {
      isMobile: this.judgeisMobile(),
    }
  },

  methods: {
    judgeisMobile () {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      return flag
    }
  }
}
</script>

<style>

</style>